import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

export const useActiveMandates = (options = {}) => {
  const queryKey = ['active-mandates']
  const query = useQuery(queryKey, () => ApiCall.Advisors.mandates(), options)

  return {
    ...query,
    data: query?.data?.data?.active,
  }
}

export const useListActiveAdvisors = (options = {}) => {
  const queryKey = ['list-advisors']
  const query = useQuery(
    queryKey,
    () => ApiCall.Advisors.getList().catch((error) => console.error(error)),
    options,
  )

  return {
    ...query,
    data: query?.data?.data,
  }
}
