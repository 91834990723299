import { Button, Form, Input, Typography, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useAuth } from 'components/auth'
import { useEffect } from 'react'
import ApiCall from 'services/ApiCall'
import { trackEvent } from 'utils/analytics'
import { extractDomain } from './ScheduleCall'

export const FormCompany = ({
  onCompleted,
  loading,
  initialValues,
  disabled,
  currentStep,
  setCurrentStep,
}) => {
  const [form] = useForm()
  const { authUser } = useAuth()

  const handleSubmitDomain = async (values) => {
    trackEvent('User submits company data', {}, authUser.uuid)
    try {
      // setIsLoading(true)
      await ApiCall.Companies.create({
        name: values.name,
        domain: extractDomain(authUser.email),
        website: extractDomain(authUser.email),
      })

      onCompleted(true)
      setCurrentStep(currentStep + 1)

      message.success('Successfully saved company info.')
    } catch (error) {
      console.error(error)
      message.error('Failed to submit form!')
    } finally {
      // setIsLoading(false)
    }
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [initialValues])

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmitDomain}
      disabled={loading || disabled}
      className="max-w-lg mx-auto onboarding-start"
    >
      <Typography variant="h3" component="h3">
        Let's Set Up Your Company Profile!
      </Typography>
      <Typography variant="body1">
        To ensure a smooth onboarding for your team, we need a few details about
        your company. This information will lay the foundation for your
        colleagues to join effortlessly in the future.
      </Typography>
      <Form.Item
        style={{ marginTop: '30px' }}
        name="name"
        label="Company Name"
        validateTrigger="onBlur"
        required
        rules={[{ required: true, message: 'Company Name is Required' }]}
      >
        <Input placeholder="Company name" />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        className="ml-auto block"
        onClick={() => ''}
      >
        Submit
      </Button>
    </Form>
  )
}
