import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

function useCompanyById({ uuid, ...props }) {
  const options = props?.options || {}
  const queryKey = ['my-user-data', uuid]
  const query = useQuery(queryKey, () => ApiCall.Companies.getById(uuid), {
    ...options,
  })

  return {
    ...query,
    data: query?.data?.data,
  }
}

export default useCompanyById
