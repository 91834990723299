import { Button, Form, Input, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useAuth } from 'components/auth'
import { ApiCall } from 'services'
import { extractDomain } from './ScheduleCall'

export const FormAdvisor = ({
  onCompleted,
  currentStep,
  setCurrentStep,
  refetchUser,
  setShowButton,
}) => {
  const [form] = useForm()
  const { authUser } = useAuth()

  const handleSubmitAdvisor = async (values) => {
    ApiCall.Advisors.create(values.name, values.domain)
      .then((response) => {
        onCompleted(true)

        setCurrentStep(currentStep + 1)
        refetchUser()
      })
      .catch((error) => {
        console.error('Advisor creation failed:', error)
      })

    // Simulate API call
    // setTimeout(() => {
    //   setCurrentStep(currentStep + 1) // Move to the next step after successful submission
    // }, 1000)
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmitAdvisor}>
      <Typography.Title level={3}>Advisor Profile Setup</Typography.Title>
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Name is required' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="domain"
        label="Company Domain"
        initialValue={extractDomain(authUser.email)}
        disabled
        rules={[{ required: true, message: 'Domain is required' }]}
      >
        <Input />
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  )
}
