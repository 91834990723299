import { Col, Row, Select } from 'antd'
import { useAdvisorSelectedCompany } from 'components/contexts/AdvisorSelectedCompany'
import { useActiveMandates } from 'data/useAdvisor'

const AdvisorCompanySelect = () => {
  const { selectedCompany, setSelectedCompany, refetch } =
    useAdvisorSelectedCompany()

  const {
    data: mandates,
    isLoading: isLoadingMandates,
    refetch: refetchMandates,
  } = useActiveMandates()

  const formatMandatesForSelect = (mandates) => {
    return mandates?.map((d) => ({
      label: d.company.name,
      value: d.company.uuid,
    }))
  }

  return (
    <>
      <Row>
        <Col
          xs={24}
          style={{
            // display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '15px',
          }}
        >
          Select company:
          <Select
            value={selectedCompany?.value}
            className="w-full"
            placeholder={
              mandates?.length > 0
                ? 'Select company'
                : 'No company has authorized your access. '
            }
            size="medium"
            options={formatMandatesForSelect(mandates)}
            onSelect={(value, option) => {
              setSelectedCompany(option)
              // refetch()
            }}
          ></Select>
        </Col>
      </Row>
    </>
  )
}
export default AdvisorCompanySelect
