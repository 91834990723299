import {
  CalendarOutlined,
  ClockCircleOutlined,
  CrownOutlined,
  LogoutOutlined,
  ProjectOutlined,
  RiseOutlined,
  SettingOutlined,
  TeamOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { PAGE } from './pages'

export const MENUS = [
  {
    key: PAGE.dashboard,
    label: 'Dashboard',
    icon: <RiseOutlined />,
  },
  {
    key: PAGE.calendarView,
    label: 'Calendar View',
    icon: <CalendarOutlined />,
  },
  {
    key: PAGE.timeRegistration,
    label: 'Time Registration',
    icon: <CalendarOutlined />,
  },
  {
    key: PAGE.leaderboard,
    label: 'WBSO Leaderboard',
    icon: <CrownOutlined />,
  },
  {
    key: PAGE.userTimeLog,
    label: 'Time Log Overview',
    icon: <ClockCircleOutlined />,
  },
  {
    key: 'admin',
    label: 'Admin',
    icon: <TeamOutlined />,
    children: [
      {
        key: PAGE.adminTimeLog,
        label: 'Time Log Overview',
        icon: <ClockCircleOutlined />,
      },
      {
        key: PAGE.projectManagement,
        label: 'Project Management',
        icon: <ProjectOutlined />,
      },
      {
        key: PAGE.userManagement,
        label: 'User Management',
        icon: <UserAddOutlined />,
      },
      {
        key: PAGE.advisorAccess,
        label: 'Advisor Access',
        icon: <SettingOutlined />,
      },
    ],
  },
  {
    key: 'logout',
    label: 'Logout',
    icon: <LogoutOutlined />,
  },
]

export const ADVISOR_MENUS = [
  {
    key: PAGE.adminTimeLog,
    label: 'Time Log Overview',
    icon: <ClockCircleOutlined />,
  },
  {
    key: PAGE.projectManagement,
    label: 'Project Management',
    icon: <ProjectOutlined />,
  },
  {
    key: PAGE.userManagement,
    label: 'User Management',
    icon: <UserAddOutlined />,
  },
  {
    key: 'logout',
    label: 'Logout',
    icon: <LogoutOutlined />,
  },
]

export const localStorageKey = 'userinformation'

export const MESSAGE = {
  ERROR_SUBMIT: 'Failed to Submit Data',
  SUCCESS_SUBMIT: 'Successfully submitted data',
  UPDATE_SUBMIT: 'Updated',
}
