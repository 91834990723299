export const PROTECTED_PAGES = {
  dashboard: '/platform/dashboard',
  calendarView: '/platform/calendar-view',
  userTimeLog: '/platform/time-log',
  leaderboard: '/platform/leaderboard',
  adminTimeLog: '/platform/admin/time-log',
  userManagement: '/platform/admin/user-management',
  projectManagement: '/platform/admin/project-management',
  advisorAccess: '/platform/admin/advisor-access',
  timeRegistration: '/platform/time-registration',
}

export const PAGE = {
  landing: '/',
  signin: '/sign-in',
  privacyPolicy: '/privacy-policy',
  dashboard: '/dashboard',
  blog: '/blog',
  backlinks: '/backlinks',
  wbsoCalculator: '/wbso-calculator',
  wbso_application: '/wbso-application',
  ...PROTECTED_PAGES,
}

export const supportedLocales = ['en', 'nl']

export const PUBLIC_PAGES_RAW = [
  PAGE.landing,
  PAGE.signin,
  PAGE.privacyPolicy,
  PAGE.blog,
  PAGE.backlinks,
  PAGE.wbsoCalculator,
]

// add the supported locales to the public pages
export const PUBLIC_PAGES = PUBLIC_PAGES_RAW.reduce((acc, page) => {
  supportedLocales.forEach((locale) => {
    acc.push(`/${locale}${page}`)
  })
  return acc
}, []).concat(PUBLIC_PAGES_RAW)
