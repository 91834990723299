import { Button, Modal, Steps } from 'antd'
import { useEffect, useState } from 'react'
import { FinalState } from './FinalState'
import { FormAdvisor } from './FormAdvisor'
import { FormCompany } from './FormCompany'
import { FormProviderCalendarManagment } from './FormProviderCalendarManagment'
import { FormProviderIssueManagment } from './FormProviderIssueManagment'
import { SelectUserType } from './SelectUserType'
import { WelcomeScreen } from './WelcomeScreen'

const ModalOnboarding = ({
  open,
  refetchUser,
  user,
  onFinished,
  ...resProps
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [showOnBoardingIntro, setShowOnboardingIntro] = useState(true)
  const [isDomainCompleted, setIsDomainCompleted] = useState(false)
  const [isProviderCompleted, setIsProviderCompleted] = useState(false)
  const [isCalendarConnected, setIsCalendarConnected] = useState(false)
  const [isAdvisorFormCompleted, setIsAdvisorFormCompleted] = useState(false)
  const [showNextButton, setShowNextButton] = useState(false)
  // const [isAdvisorCallScheduled, setIsAdvisorCallScheduled] = useState(false)
  const [userType, setUserType] = useState(null)

  // Replace with your actual API call logic as needed

  // const { data: company } = useCompanyById({
  //   uuid: user?.company?.uuid,
  //   options: { enabled: !!user?.company },
  // })

  useEffect(() => {
    if (user?.company) {
      setUserType('user')
    } else if (user?.is_advisor) {
      setUserType('advisor')
    }

    if (user?.is_advisor || isAdvisorFormCompleted) {
      setIsAdvisorFormCompleted(true)
      setCurrentStep(1)
    }

    if (
      !isDomainCompleted &&
      !isProviderCompleted &&
      !isCalendarConnected &&
      showOnBoardingIntro &&
      userType === 'user'
    ) {
      setCurrentStep(1)
    }

    if (user?.company && userType === 'user') {
      setIsDomainCompleted(true)
      setCurrentStep(2)
    }
    if (user?.issue_management_provider?.length > 0 && userType === 'user') {
      setIsProviderCompleted(true)
      setCurrentStep(3)
    }
    if (
      isProviderCompleted &&
      user?.calendar_provider?.length > 0 &&
      userType === 'user'
    ) {
      setIsCalendarConnected(true)
      setCurrentStep(4)
    }
  }, [open, user, currentStep, refetchUser, userType])

  const stepComponents = {
    user: [
      { component: WelcomeScreen, onCompleted: null },
      { component: FormCompany, onCompleted: setIsDomainCompleted },
      {
        component: FormProviderIssueManagment,
        onCompleted: setIsProviderCompleted,
      },
      {
        component: FormProviderCalendarManagment,
        onCompleted: setIsCalendarConnected,
      },
      { component: FinalState, onCompleted: null },
    ],
    advisor: [
      {
        component: FormAdvisor,
        onCompleted: setIsAdvisorFormCompleted,
        refetchUser: refetchUser,
        setShowButton: setShowNextButton,
      },
      { component: FinalState, onCompleted: null },
      // { component: ScheduleCall, onCompleted: null },
    ],
    null: [
      { component: SelectUserType, onCompleted: setIsAdvisorFormCompleted },
      { component: null, onCompleted: null },
      { component: null, onCompleted: null },
      { component: null, onCompleted: null },
      { component: null, onCompleted: null },
    ],
  }

  const renderStepComponent = () => {
    const Component = stepComponents[userType][currentStep]
    if (!Component?.component) return null // Return null or a fallback component if the component is not found

    if (Component.component === SelectUserType) {
      return (
        <Component.component
          onSelect={(type) => {
            setUserType(type)
            setCurrentStep(0)
          }}
        />
      )
    } else if (Component.component === FinalState) {
      return <Component.component isCompleted={true} />
    } else {
      // Pass props as needed to each component
      return (
        <Component.component
          handleSubmit={null} // Example prop, replace with actual props needed
          loading={isLoading}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onCompleted={Component.onCompleted}
          onScheduled={() => {
            onFinished()
            refetchUser()
          }}
        />
      )
    }
  }

  return (
    <Modal
      title="Onboarding - Traqqie.com"
      open={open}
      footer={null}
      destroyOnClose
      width="800px"
      {...resProps}
    >
      <Steps current={currentStep} direction="horizontal">
        {stepComponents[userType].map((_, index) => (
          <Steps.Step key={index} />
        ))}
      </Steps>
      <div className="steps-content" style={{ marginTop: '24px' }}>
        {renderStepComponent()}
      </div>
      <div
        className="steps-action"
        style={{ marginTop: '24px', textAlign: 'right' }}
      >
        {showNextButton &&
          currentStep < stepComponents[userType].length - 1 && (
            <Button
              type="primary"
              onClick={() => {
                setCurrentStep(currentStep + 1)
              }}
            >
              Next
            </Button>
          )}
        {currentStep === stepComponents[userType].length - 1 && (
          <Button type="primary" onClick={onFinished}>
            Done
          </Button>
        )}
        {currentStep > 0 && (
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Previous
          </Button>
        )}
      </div>
    </Modal>
  )
}

// return (
//   <>
//     <Modal
//       title="Onboarding - Traqqie.com"
//       open={open}
//       footer={false}
//       destroyOnClose
//       width="60%"
//       {...resProps}
//     >
//       <Steps
//         current={currentStep}
//         items={[
//           { title: 'Welcome' },
//           { title: 'Company' },
//           { title: 'Issue Management' },
//           { title: 'Calendar' },
//           { title: 'Ready to go' },
//         ]}
//       />
//       <Row className="w-full">
//         <Col span={24} className="py-6">
//           {currentStep === 0 && <WelcomeScreen />}
//           {currentStep === 1 && (
//             <FormCompany
//               handleSubmit={handleSubmitDomain}
//               loading={isLoading}
//               initialValues={company || {}}
//               disabled={isDomainCompleted}
//             />
//           )}
//           {currentStep === 2 && (
//             <FormProviderIssueManagment
//               completed={isProviderCompleted}
//               checkStatus={refetchUser}
//             />
//           )}
//           {currentStep === 3 && (
//             <FormProviderCalendarManagment
//               completed={isCalendarConnected}
//               checkStatus={refetchUser}
//             />
//           )}
//           {currentStep === 4 && (
//             <FinalState
//               isCompleted={
//                 isDomainCompleted &&
//                 isProviderCompleted &&
//                 isCalendarConnected
//               }
//             />
//           )}
//           <Space className="mt-6 ml-auto flex w-max" direction="horizontal">
//             <Button
//               hidden={currentStep === 0}
//               onClick={() => setCurrentStep((p) => p - 1)}
//             >
//               Previous
//             </Button>
//             <Button
//               type="primary"
//               loading={isLoading}
//               onClick={() => {
//                 if (currentStep < 4) {
//                   setShowOnboardingIntro(false)
//                   setCurrentStep((p) => p + 1)
//                 } else {
//                   onFinished()
//                   refetchUser()
//                 }
//               }}
//               disabled={
//                 (currentStep === 1 && !isDomainCompleted) ||
//                 (currentStep === 2 && !isProviderCompleted) ||
//                 (currentStep === 3 && !isCalendarConnected)
//               }
//             >
//               {currentStep !== 4 ? 'Next' : "Let's go!"}
//             </Button>
//           </Space>
//         </Col>
//       </Row>
//     </Modal>
// </>
// )
// }

export default ModalOnboarding
