export const trackEvent = async (event, eventProps, userId = null) => {
  window.rudderanalytics.track(event, { ...eventProps, userId })
}

export const isBrowser = () => typeof window !== 'undefined'

export const trackPageView = async () => {
  if (isBrowser()) {
    window.rudderanalytics.page()
  }
}

export const identifyUser = async (platformUuid, name, email) => {
  if (isBrowser()) {
    window.rudderanalytics.identify(platformUuid, { name, email })
  }
}

export const identifyAnonymousUser = async (email, name) => {
  if (isBrowser()) {
    window.rudderanalytics.identify()
  }
}
