import axios from 'axios'
import 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  getAuth,
  getIdToken,
  onAuthStateChanged,
  setPersistence,
  signInWithPopup,
} from 'firebase/auth'
import { v4 as uuidv4 } from 'uuid'

import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useState } from 'react'
import { identifyUser } from 'utils/analytics'
import { getLocalStorageKey, saveToLocalStorage } from 'utils/helpers'
import { PAGE, PUBLIC_PAGES } from 'utils/pages'

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
}

initializeApp(firebaseConfig)

const callMe = async (token) => {
  return await axios
    .get(`${process.env.BACKEND_API}/users/me/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.data)
}

const googleProvider = new GoogleAuthProvider()
export const FirebaseContext = createContext({})

export const FirebaseProvider = (props) => {
  const router = useRouter()
  const auth = getAuth()
  const [authUser, setAuthUser] = useState(null)
  const [authError, setAuthError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const clearStorage = () => {
    localStorage.removeItem(getLocalStorageKey())
    Cookies.remove('refreshToken')
  }

  const createDemoAccount = async () => {
    createUserWithEmailAndPassword(
      auth,
      `demo+${uuidv4()}@traqqie.com`,
      'password',
    )
  }

  const signInWithGoogleAccount = async ({ keepLoggedIn = false }) => {
    try {
      setIsLoading(true)
      if (keepLoggedIn) {
        setPersistence(auth, browserLocalPersistence)
      }
      await signInWithPopup(auth, googleProvider)
    } catch (error) {
      setAuthError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const signOut = async () => {
    try {
      setIsLoading(true)

      await firebaseSignOut(auth)
      clearStorage()

      setAuthUser(null)

      router.push(PAGE.landing)
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, async (userId) => {
      const subRoute = `/${router.pathname.split('/')[1]}`

      const isPublicPage =
        PUBLIC_PAGES.includes(router.pathname) ||
        PUBLIC_PAGES.includes(subRoute)

      if (!userId && !isPublicPage) {
        clearStorage()

        setAuthUser(null)
      }

      if (userId?.emailVerified || userId?.email.includes('@traqqie.com')) {
        const token = await getIdToken(userId, true)
        let user

        if (token && token !== undefined) {
          user = await callMe(token)
          identifyUser(user.uuid, user.display_name, user.email)

          setAuthUser(user)

          saveToLocalStorage({
            token,
            refreshToken: userId.refreshToken,
            user: {
              ...userId,
              company: user?.company,
              issue_management_provider: user?.issue_management_provider,
              is_advisor: user?.is_advisor,
            },
          })
          if (user?.is_advisor) {
            router.push(PAGE.adminTimeLog)
          } else {
            router.push(PAGE.dashboard)
          }
        }
      }
    })
  }, [])

  return (
    <FirebaseContext.Provider
      value={{
        authUser,
        authError,
        setAuthError,
        isLoading,
        createDemoAccount,
        signInWithGoogleAccount,
        signOut,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(FirebaseContext)
}
