import { Col, Radio, Row, Typography } from 'antd'
import 'antd/dist/antd.css' // Ensure you import Ant Design styles

export const SelectUserType = ({ onSelect }) => (
  <Row className="max-w-xl mx-auto py-12" gutter={[16, 24]}>
    <Col span={24}>
      <Typography.Title level={3}>Get Started with Traqqie</Typography.Title>
    </Col>
    <Col span={24}>
      <Typography.Text>
        To provide you with a personalized experience, please select the option
        below that best describes your role:
      </Typography.Text>
      <Radio.Group
        onChange={(e) => onSelect(e.target.value)}
        style={{ marginTop: '16px', width: '100%' }}
      >
        <Radio.Button
          value="user"
          style={{
            display: 'block',
            height: '50px',
            lineHeight: '50px',
            marginBottom: '10px',
            textAlign: 'center',
          }}
        >
          I am an employee, I need to track my WBSO hours
        </Radio.Button>
        <Radio.Button
          value="advisor"
          style={{
            display: 'block',
            height: '50px',
            lineHeight: '50px',
            textAlign: 'center',
          }}
        >
          I am a WBSO advisor, I want to help my clients
        </Radio.Button>
      </Radio.Group>
    </Col>
  </Row>
)
